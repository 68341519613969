<template>
    <!--
    Компонент диаграммы наличия кворума
    -->
    <el-row :gutter="20" align="middle" justify="center">
        <div class="chart_wrapper">
            <div class="chart_title">
                <div style="position: absolute; left: 50%; transform: translate(-50%)">{{ title }}</div>
            </div>
            <el-row>
                <div class="chart_rows_wrapper" :style="{gridTemplateColumns: chartColumns}">
                    <div class="chart_inner"
                         v-for="(row, idx) in filteredChartData" :key="idx"
                         :style="{backgroundColor: filteredChartColors[idx]}">{{ (row * 100).toFixed(0) }}%
                    </div>
                </div>
            </el-row>
            <div class="chart_vote_percent">
                <div style="position: absolute; top: 0; left: 0">0%</div>
                <div style="position: absolute; left: 50%; transform: translate(-50%)" class="percent_divider">50%</div>
                <div style="position: absolute; top: 0; right: 0">100%</div>
            </div>
        </div>
    </el-row>
</template>

<script>
import {mapFields} from "vuex-map-fields";

export default {
    name: "DomVoteChart",
    props: {
        chartColors: {},
        question: {},
        voting_id: {},
        title: {}
    },
    computed: {
        ...mapFields("voting", {voteStatistics: "statistics"}),

        stat() {
            return this.voteStatistics || {};
        },

        voteChartData() {
            return [this.voted_percent / 100, this.did_not_voted_percent / 100]
        },

        filteredChartData() {
            return this.voteChartData ? this.voteChartData.filter(el => el) : []
        },
        filteredChartColors() {
            return this.chartColors.filter((el, i) => this.voteChartData[i]);
        },

        chartColumns() {
            if (this.filteredChartData)
                return this.filteredChartData
                    .map(el => `${Math.floor(el * 1000)}fr`)
                    .join(' ');
        },

        voted_percent() {
            return Math.ceil(this.stat.total > 0 ? 100 * this.stat.voted_m2 / this.stat.total_m2 : 0)
        },

        did_not_voted_percent() {
            return 100 - this.voted_percent
        },
    }
}
</script>

<style scoped lang="scss">
@import "~@/theme.scss";

.chart_wrapper {
    max-width: 600px;
    margin-left: 20px;
}

.percent_divider::before {
    border: 7px solid transparent;
    border-bottom-color: $dom-gray;
    content: '';
    position: absolute;
    top: -15px;
    display: block;
    width: 0;
    height: 0;
    margin-left: 8px;
}

.percent_divider::after {
    border-left: 2px dashed white;
    content: '';
    position: absolute;
    top: -45px;
    display: block;
    width: 0;
    height: 30px;
    margin-left: 15px;
}

.chart_title {
    position: relative;
    color: #545454;
    opacity: 0.7;
    margin-bottom: 20px;
    margin-top: 15px;
    height: 25px;
}

.chart_vote_percent {
    position: relative;
    color: #545454;
    opacity: 0.7;
    margin-top: 15px;
    height: 25px;
}

.chart_title {
    text-align: center;
    color: #545454;
    opacity: 0.7;
    margin-bottom: 2px;
    font-size: 16px;
}

.chart_rows_wrapper {
    height: 30px;
    display: grid;
    flex-direction: column;
    column-gap: 2px;
}

.chart_rows_wrapper :first-child {
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
}

.chart_rows_wrapper :last-child {
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
}

.chart_inner {
    text-align: center;
    color: white;
    font-size: 18px;
    font-weight: bold;
    line-height: 30px;

}

</style>
