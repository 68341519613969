<template>
    <!--
    Компонент выпадающего списка с поиском
    -->

    <el-container class="dom-dropdown">
        <i :class="leftIcon" class="dropdown-icon" v-if="leftIcon"/>

        <span class="dom-dropdown-value" v-if="valueToDisplay">{{ valueToDisplay }}</span>
        <span class="dom-dropdown-value" v-else>{{ label }}</span>

        <el-dropdown v-model="value" @command="onCommand" style="height: 100%" trigger="click">

            <template>
                <dom-button style="justify-self: right; height: 100%"
                            :left-icon="iconInner || 'fas el-icon-fa-chevron-circle-down'"
                >
                </dom-button>
            </template>


            <el-dropdown-menu slot="dropdown" style="padding-top: 1px;">
                <el-input class="dom-human" v-model="search" placeholder="Поиск" style="font-size: 16px; min-width: 350px">
                    <i slot="prefix" class="el-icon-search el-input__icon"/>
                </el-input>
                <el-dropdown-item v-if="items" v-for="item in filteredItems" :command="item" style="font-size: 18px; padding: 8px">
                    <span v-html="highlight(item[textKey] )"></span>
                </el-dropdown-item>
            </el-dropdown-menu>
        </el-dropdown>
    </el-container>

</template>

<script>
import DomButton from "@/views/elements/DomButton";
import ValueInternal from '@/views/mixins/ValueInternal';

export default {
    name: "dom-dropdown-search",
    components: {DomButton},
    mixins: [ValueInternal],

    props: {
        label: {
            default: ''
        },
        iconInner: {},
        items: {
            type: Array,
            required: true,
        },
        leftIcon: {},
        valueKey: {
            type: String,
            default: 'id'
        },
        textKey: {
            type: String,
            default: 'text'
        },
        useKey: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            search: '',
        }
    },

    computed: {
        valueToDisplay() {
            if (this.useKey) {
                const found = _.find(this.items, d => d[this.valueKey] === this.value);
                return found ? found[this.textKey] : ""
            } else {
                return this.value ? this.value[this.textKey] : ""
            }
        },
        filteredItems() {
            return _(this.items)
                .filter(d => this.search !== null && this.search.length ? d.user_name.toLowerCase().indexOf(this.search.toLowerCase()) !== -1 : true)
                .orderBy(d => d.user_name.toLowerCase())
                .value()
        }
    },

    methods: {
        onCommand(item) {
            if (this.useKey)
                this.$emit('input', item[this.valueKey])
            else
                this.$emit('input', item)
        },
        highlight(d) {
            const search = this.search !== null ? this.search.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&') : "";
            const r = new RegExp(search, "gi");
            return search !== null && search.length ? d.replace(r, "<span class='highlight'>$&</span>") : d
        },
    }

}
</script>

<style scoped lang="scss">

@import "~@/theme.scss";

.dom-dropdown {
    background-color: $dom-white;
    border-radius: 5px;
    width: 100%;
    display: flex;
    align-items: center;
    word-break: break-word;

    .dom-dropdown-value {
        flex-grow: 1;
        padding: 15px;
        font-size: 20px;
    }
}

.dropdown-icon {
    color: $dom-light-blue;
    padding-left: 20px;
    font-size: 20px;
}

.dom-dropdown-content {
    justify-content: flex-end;
    padding: 10px 10px 10px 20px;
    display: flex;
}

.el-dropdown-menu {
    max-height: 80vh;
    overflow-y: auto;
}

</style>
