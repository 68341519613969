<template>
    <!--
    Компонент инфографики результатов голосования
    -->
    <el-row :gutter="20" align="middle" justify="center" style="padding-right: 20px;">
        <div class="chart_wrapper">
            <div class="chart_title">
                <div style="position: absolute; transform: translate(-50%)"
                     v-if="question.algorithmCd !== 'alg_5'"
                     :style="{left:algorithmPercent[question.algorithmCd] || '50%'}">{{ title }}
                </div>
            </div>
            <el-row>
                <div class="chart_rows_wrapper" :style="{gridTemplateColumns: chartColumns}">
                    <div class="chart_inner"
                         v-for="(row, idx) in filteredChartData" :key="idx"
                         :style="{backgroundColor: filteredChartColors[idx]}">{{ (Math.abs(row * 100)).toFixed(0) }}%
                    </div>
                </div>
            </el-row>
            <div class="chart_vote_percent">
                <div style="position: absolute; top: 0; left: 0">0%</div>
                <div
                    style="position: absolute; transform: translate(-50%)"
                    :style="{left:algorithmPercent[question.algorithmCd]  || '50%'}"
                    class="percent_divider"
                    v-if="question.algorithmCd !== 'alg_5' && question.algorithmCd !== 'alg_4'"
                >{{ dividerPosition }}
                </div>
                <div style="position: absolute; top: 0; right: 0">100%</div>
            </div>
        </div>
    </el-row>
</template>

<script>
import {mapFields} from "vuex-map-fields";

export default {
    name: "DomVoteChart",
    props: {
        chartColors: {},
        question: {},
        title: {}
    },
    data: () => ({
        algorithmPercent: {
            alg_1: '50%',
            alg_2: '50%',
            alg_3: '66.6%',
            alg_4: '100%',
        },
    }),
    methods: {},
    computed: {
        ...mapFields("voting", {voteStatistics: "statistics", currentPage: "currentPage"}),

        algMax() {
            return this.question.algorithmCd === 'alg_1' ? this.statTotal.voted_m2 : this.statTotal.total_m2
        },

        dividerPosition() {
            switch (this.question.algorithmCd) {
                case 'alg_1':
                    return '50%';
                case 'alg_2':
                    return '50%';
                case 'alg_3':
                    return '2/3';
                case 'alg_4':
                    return '100%';
                default:
                    return '50%'
            }
        },
        questionId() {
            return this.question.questionId;
        },
        usersStatistic() {
            return this.stat ? (this.stat.users || []) : [];
        },

        stat() {
            if (this.questionId && this.voteStatistics.byQuestion) return this.voteStatistics.byQuestion[this.questionId];
            else return {}
        },

        statTotal() {
            return this.voteStatistics || {};
        },

        filteredChartData() {
            return this.voteChartData ? this.voteChartData.filter(el => el) : []
        },
        filteredChartColors() {
            return this.chartColors.filter((el, i) => this.voteChartData[i]);
        },
        chartColumns() {
            return this.filteredChartData
                .map(el => `${Math.floor(Math.abs(el * 1000))}fr`)
                .join(' ');
        },

        voteChartData() {
            return this.question.algorithmCd === 'alg_1'
                ? this.agree_percent + this.disagree_percent + this.abstain_percent === 0 ? [0, 0, 0, 1] : [+this.agree_percent, +this.disagree_percent, +this.abstain_percent]
                : [+this.agree_percent, +this.disagree_percent, +this.abstain_percent, +this.did_not_voted_percent]
        },

        voted_percent() {
            return Math.ceil(this.statTotal.total > 0 ? 100 * this.statTotal.voted_m2 / this.statTotal.total_m2 : 0) / 100
        },

        did_not_voted_percent() {
            return 1 - this.voted_percent
        },

        agree_percent() {
            return Math.ceil(this.statTotal.voted_m2 > 0 ? 100 * this.stat.agree_m2 / this.algMax : 0) / 100 > 1
                ? 1
                : Math.ceil(this.statTotal.voted_m2 > 0 ? 100 * this.stat.agree_m2 / this.algMax : 0) / 100
        },

        disagree_percent() {
            if (!this.stat.agree_m2 && !this.stat.abstain_m2) return this.question.algorithmCd === 'alg_1' && this.stat.disagree_m2 ? 1 : this.voted_percent
            else if (this.stat.agree_m2 && !this.stat.abstain_m2) return this.question.algorithmCd === 'alg_1' ? 1 - this.agree_percent : this.voted_percent - this.agree_percent
            else return (this.statTotal.voted_m2 > 0 ? 100 * this.stat.disagree_m2 / this.algMax : 0) / 100
        },

        abstain_percent() {
            if (this.question.algorithmCd === 'alg_1') {
                if (this.stat.abstain_m2) return 1 - this.agree_percent - this.disagree_percent
                else return 0
            } else {
                if (this.stat.abstain_m2) return 1 - this.agree_percent - this.disagree_percent - this.did_not_voted_percent
                else return 0
            }
        },
    }
}
</script>

<style scoped lang="scss">
@import "~@/theme.scss";

.chart_wrapper {
    max-width: 600px;
    margin-left: 20px;
}

.percent_divider::before {
    border: 7px solid transparent;
    border-bottom-color: $dom-gray;
    content: '';
    position: absolute;
    top: -15px;
    display: block;
    width: 0;
    height: 0;
    margin-left: 8px;
}

.percent_divider::after {
    border-left: 2px dashed white;
    content: '';
    position: absolute;
    top: -45px;
    display: block;
    width: 0;
    height: 30px;
    margin-left: 14px;
}

.chart_title {
    position: relative;
    color: #545454;
    opacity: 0.7;
    margin-bottom: 20px;
    height: 25px;
}

.chart_vote_percent {
    position: relative;
    color: #545454;
    opacity: 0.7;
    margin-top: 15px;
    height: 25px;
}

.chart_title {
    text-align: center;
    color: #545454;
    opacity: 0.7;
    margin-bottom: 2px;
    font-size: 16px;
}

.chart_rows_wrapper {
    height: 30px;
    display: grid;
    flex-direction: column;
    column-gap: 2px;
}

.chart_rows_wrapper :first-child {
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
}

.chart_rows_wrapper :last-child {
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
}

.chart_inner {
    text-align: center;
    color: white;
    font-size: 20px;
    font-weight: bold;
    line-height: 30px;

}

</style>
