<template>
    <!--
    Компонент статистики голосования
    -->
    <div>
        <el-row style="margin-top: 10px;" :gutter="20" align="middle" justify="center">
            <el-col style="max-width: 500px" class="media-col" v-if="!disableLeftBlock">
                <el-row class="vote-row">
                    <el-col :span="4" class="vote-row__percent" style="background: #07C98C">{{ agree_percent }}%</el-col>
                    <el-col :span="16" class="vote-row__text">Проголосовали "За"</el-col>
                    <el-col :span="6" class="vote-row__count">
                        <template v-if="showByPerson">
                            <div>{{ stat.agree }}</div>
                            <i class="fas el-icon-fa-users vote-row__count"/>
                        </template>
                        <template v-else>
                            <div>{{ stat.agree_m2 | float2 }}</div>
                            <i class="fas el-icon-fa-users vote-row__count"/>
                        </template>
                    </el-col>
                </el-row>
                <el-row class="vote-row">
                    <el-col :span="4" class="vote-row__percent" style="background: #E93330">{{ disagree_percent }}%</el-col>
                    <el-col :span="16" class="vote-row__text">Проголосовали "Против"</el-col>
                    <el-col :span="6" class="vote-row__count">
                        <template v-if="showByPerson">
                            <div>{{ stat.disagree }}</div>
                            <i class="fas el-icon-fa-users vote-row__count"/>
                        </template>
                        <template v-else>
                            <div>{{ stat.disagree_m2 | float2 }}</div>
                            <i class="fas el-icon-fa-users vote-row__count"/>
                        </template>
                    </el-col>
                </el-row>
                <el-row class="vote-row">
                    <el-col :span="4" class="vote-row__percent" style="background: #EECB00">{{ abstain_percent }}%</el-col>
                    <el-col :span="16" class="vote-row__text">Воздержались</el-col>
                    <el-col :span="6" class="vote-row__count">
                        <template v-if="showByPerson">
                            <div>{{ stat.abstain }}</div>
                            <i class="fas el-icon-fa-users vote-row__count"/>
                        </template>
                        <template v-else>
                            <div>{{ stat.abstain_m2 | float2 }}</div>
                            <i class="fas el-icon-fa-users vote-row__count"/>
                        </template>
                    </el-col>
                </el-row>
            </el-col>
            <el-col style="max-width: 500px; padding-right: 10px;" class="media-col">
                <el-row class="vote-row">
                    <el-col :span="4" class="vote-row__percent" style="background: #1d9ef9">{{ voted_percent }}%</el-col>
                    <el-col :span="16" class="vote-row__text">Всего проголосовали</el-col>
                    <el-col :span="6" class="vote-row__count">
                        <template v-if="showByPerson">
                            <div>{{ statTotal.voted }}</div>
                            <i class="fas el-icon-fa-users vote-row__count"/>
                        </template>
                        <template v-else>
                            <div>{{ statTotal.voted_m2 | float2 }}</div>
                            <i class="fas el-icon-fa-users vote-row__count"/>
                        </template>
                    </el-col>
                </el-row>
                <el-row class="vote-row">
                    <el-col :span="4" class="vote-row__percent" style="background: #AEB2BB">{{ did_not_voted_percent }}%</el-col>
                    <el-col :span="16" class="vote-row__text">Не проголосовали</el-col>
                    <el-col :span="6" class="vote-row__count">
                        <template v-if="showByPerson">
                            <div>{{ statTotal.did_not_voted }}</div>
                            <i class="fas el-icon-fa-users vote-row__count"/>
                        </template>
                        <template v-else>
                            <div>{{ statTotal.did_not_voted_m2 | float2 }}</div>
                            <i class="fas el-icon-fa-users vote-row__count"/>
                        </template>
                    </el-col>
                </el-row>
                <el-row class="vote-row">
                    <el-col :span="20" class="vote-row__text">Всего голосов</el-col>
                    <el-col :span="4" class="vote-row__count">
                        <template v-if="showByPerson">
                            <div>{{ statTotal.total }}</div>
                            <i class="fas el-icon-fa-users vote-row__count"/>
                        </template>
                        <template v-else>
                            <div>{{ statTotal.total_m2 | float2 }}</div>
                            <i class="fas el-icon-fa-users vote-row__count"/>
                        </template>
                    </el-col>
                </el-row>
            </el-col>
        </el-row>
    </div>

</template>
<script>
import {mapFields} from "vuex-map-fields";

export default {
    props: {
        question: {},
        isShow: Boolean,
        disableLeftBlock: {}
    },

    data: () => ({
        showByPerson: false,
        perPage: 10,
    }),

    methods: {},

    computed: {
        ...mapFields("voting", {voteStatistics: "statistics"}),

        algMax() {
            return this.question.algorithmCd === 'alg_1' ? this.statTotal.voted_m2 : this.statTotal.total_m2
        },

        questionId() {
            return this.question.questionId;
        },

        stat() {
            if (this.questionId && this.voteStatistics.byQuestion) return this.voteStatistics.byQuestion[this.questionId];
            else return {}
        },

        statTotal() {
            return this.voteStatistics || {};
        },

        //количество проголосовавших всегда округляем вверх
        voted_percent() {
            return Math.ceil(this.statTotal.total > 0 ? 100 * this.statTotal.voted_m2 / this.statTotal.total_m2 : 0)
        },

        did_not_voted_percent() {
            return 100 - this.voted_percent
        },

        //ЗА всегда округляется вверх до целого
        agree_percent() {
            return Math.ceil(this.statTotal.voted_m2 > 0 ? 100 * this.stat.agree_m2 / this.algMax : 0) > 100
                ? 100
                : Math.ceil(this.statTotal.voted_m2 > 0 ? 100 * this.stat.agree_m2 / this.algMax : 0)
        },
        //НЕТ всегда округляется вниз до целого
        disagree_percent() {
            if (!this.stat.agree_m2 && !this.stat.abstain_m2) return this.question.algorithmCd === 'alg_1' && this.stat.disagree_m2 ? 100: this.voted_percent
            else if (this.stat.agree_m2 && !this.stat.abstain_m2) return this.question.algorithmCd === 'alg_1' ? 100 - this.agree_percent : this.voted_percent - this.agree_percent
            else return (this.statTotal.voted_m2 > 0 ? 100 * this.stat.disagree_m2 / this.algMax : 0).toFixed(0)
        },
        abstain_percent() {
            if (this.question.algorithmCd === 'alg_1') {
                if (this.stat.abstain_m2) return 100 - this.agree_percent - this.disagree_percent
                else return 0
            } else {
                if (this.stat.abstain_m2) return 100 - this.agree_percent - this.disagree_percent - this.did_not_voted_percent
                else return 0
            }
        },
    },

}
</script>

<style scoped lang="scss">

@import "~@/theme.scss";

i.fas {
    color: $dom-light-blue;
}

.vote-card {

    .vote-row.el-row {
        border-radius: 30px;
        background: $dom-light-gray;
        height: 30px;
        min-height: 30px;
        max-height: 30px;
        display: flex;
        align-items: center;
        padding: 0;
        margin-bottom: 10px;

        .vote-row__percent {
            color: $dom-white;
            font-weight: 600;
            height: 30px;
            font-size: 14px;
            max-width: 60px;
            text-align: center;
            line-height: 30px;
            border-radius: 30px 0 0 30px;
        }

        .vote-row__text {
            padding: 0 20px;
        }

        .vote-row__count {
            margin-right: 5px;
            color: $dom-light-blue;
            font-weight: 600;
            font-size: 14px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }

        i {
            padding-left: 7px;
        }
    }
}

</style>
