<template>
    <!--
    Диалог отказа в проведении голосования
    -->
    <el-dialog
        :width="dialogWidth"
        class="paper-dialog"
        :class="{dialog_full_display: !$isMobile()}"
        :visible.sync="isActive"
        append-to-body
        center
        :close-on-click-modal="false"
        :show-close="false"
    >
        <template v-slot:title>
            <dom-title-closable value="Причина отказа в проведении голосования" @close="onCancel"/>
        </template>

        <el-row>
            <dom-textarea
                v-model="reason"
                icon-left="el-icon-info"
                placeholder="Укажите причину отказа"
            />
        </el-row>
        <el-row>
            <dom-textarea
                v-model="legalReason"
                icon-left="el-icon-info"
                placeholder="Укажите правовое основание (номер статьи ЖК РФ)"
            />
        </el-row>
        <el-row>
            <el-col :span="4">
                <dom-button-accept label="Подтвердить и завершить" :disabled="!formIsReady" @click="codeConfirmationDialog=true"/>
                <voting-status-confirmation-dialog v-model="codeConfirmationDialog" :additional-info="additionalInfo" status='declined' @done="onDone"/>
            </el-col>
        </el-row>


    </el-dialog>
</template>

<script>
import Toggleable from "@/views/mixins/Toggleable";
import DomButton from "@/views/elements/DomButton";
import DomTextarea from "@/views/elements/DomTextarea";
import DomButtonAccept from "@/views/elements/DomButtonAccept";
import CodeConfirmationDialog from "@/views/dialogs/CodeConfirmationDialog";
import VotingConfirmationDialog from "@/views/dialogs/VotingStatusConfirmationDialog";
import VotingStatusConfirmationDialog from "@/views/dialogs/VotingStatusConfirmationDialog";
import DomTitleClosable from "@/views/elements/DomTitleClosable";
import VueScreenSize from "vue-screen-size";

export default {
    name: "VoteDenyDialog",
    props: {},
    mixins: [Toggleable, VueScreenSize.VueScreenSizeMixin],
    components: {DomTitleClosable, VotingStatusConfirmationDialog, VotingConfirmationDialog, CodeConfirmationDialog, DomButtonAccept, DomButton, DomTextarea},
    data() {
        return {
            reason: '',
            legalReason: '',
            codeConfirmationDialog: false,
        }
    },

    computed: {
        dialogWidth() {
            if (this.$vssWidth > 800) return '80%'
            else return '100%'
        },

        formIsReady() {
            return !!this.reason && !!this.legalReason
        },

        additionalInfo() {
            return {
                reason: this.reason,
                legalReason: this.legalReason
            }
        }
    },

    methods: {

        __onToggleOn() {
            this.reason = null;
            this.legalReason = null;
        },

        onCancel() {
            this.isActive = false;
        },

        onDone() {
            this.$emit('done');
            this.isActive = false;
        },

    }
}
</script>

<style scoped lang="scss">
@import "~@/theme.scss";
.dialog_full_display {
    max-height: 70vh;
    margin-top: 35px;
    width: 100%;
}
.paper-dialog {
    ::v-deep .el-dialog {
        height: 100vh !important;
        margin-top: 0 !important;
    }
    .question__upload_button {
        width: 300px;
        padding: 10px 0;
        vertical-align: center;
        text-align: center;
        color: $dom-light-blue;
        border-radius: 5px;
        border: 2px solid $dom-light-blue;

        ::v-deep i {
            font-size: 18px;
        }

        ::v-deep span {
            margin: 0 10px;
            line-height: 20px;
            font-size: 14px;
        }

    }
    ::v-deep .el-dialog__body {
        background: $dom-light-gray;
    }
    //радио кнопки
    ::v-deep .el-radio__input {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        padding: 2px;
        border: 2px solid $dom-light-blue;
    }

    ::v-deep .el-radio__input.is-checked {
        .el-radio__inner {
            border-radius: 50%;
            width: 100%;
            height: 100%;
            background: $dom-green;
        }
    }

    ::v-deep .el-radio__inner {
        border-radius: 50%;
        width: 100%;
        height: 100%;
    }

    ::v-deep .vote-radio span {
        font-size: 16px;
    }

    .vote-radio {
        margin-left: 45px;
        padding: 10px 0;
    }

    .paper-dropdown {
        background: $dom-white;
        min-height: 40px;
        margin-bottom: 12px;
        margin-top: 10px;
        border-radius: 4px;

        ::v-deep i {
            color: $dom-light-blue
        }

        .el-container.dom-dropdown.dropdown_button {
            margin-bottom: 0;

            ::v-deep i {
                color: $dom-white;
            }
        }
    }

    .paper-row {
        padding: 7px;
        background: $dom-white;
        min-height: 30px;
        margin-bottom: 12px;
        margin-top: 10px;
        border-radius: 4px;

        ::v-deep i {
            color: $dom-light-blue
        }

        .el-container.dom-dropdown.dropdown_button {
            margin-bottom: 0;

            ::v-deep i {
                color: $dom-white;
            }
        }

        ::v-deep .question__index {
            width: 25px;
            height: 25px;
            line-height: 25px;
            color: $dom-white;
            background: $dom-green;
            font-size: 18px;
            border-radius: 50%;
            margin-left: 10px;
        }

        ::v-deep .question__title {
            background: $dom-light-gray;
            border-radius: 53px;
            font-size: 14px;
            min-height: 30px;

        }

        ::v-deep .question__title_icon {
            color: $dom-light-blue;
            padding-right: 10px;
            font-size: 24px;
        }
    }
}
</style>
