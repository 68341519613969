<template>
    <!--
    Компонент для загрузки файла на сервер
    -->

    <el-container class="dom-upload" >

        <i v-if="prefixIcon" :class="prefixIcon" class="prefix-icon ml-15"/>

        <span class="text"><slot/></span>

        <el-upload class="el-upload-browse"
                   :auto-upload="false"
                   :show-file-list="false"
                   :limit="limit"
                   action=""
                   :on-change="onChange">
            Обзор
        </el-upload>

    </el-container>

</template>

<script>
export default {
  name: "dom-upload",
  props: {

      prefixIcon: {
          default: "fas el-icon-fa-file-upload"
      },

      limit: {
          type: Number,
          default: 10485760,
      },
  },

    methods: {
      onChange(file, fileList) {
          this.$emit('input', {file, fileList})
      }
    }
}
</script>

<style scoped lang="scss">

@import "~@/theme.scss";

.dom-upload {
    padding: 10px 20px 10px 7px;

    align-items: center;

    text-align: left;
    color: $dom-light-blue;
    border-radius: 5px;
    border: 2px solid $dom-light-blue;
    background-color: $dom-white;

    width: fit-content;
}

i.prefix-icon {
    font-size: 18px;
}

.text {
    margin: 0 10px;
    margin-left: 20px;
    line-height: 20px;
    font-size: 14px;
}

.el-upload-browse ::v-deep .el-upload,
{
    color: $dom-white;
    background-color: $dom-light-blue;
    padding: 5px 10px;
}


</style>