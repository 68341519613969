<template>
    <!--
    Компонент отображения файла, который можно скачать
    -->

    <el-link @click="downloadFile" target="_blank" :class="{'long-variant' : longVariant}">
        <i v-if="prefixIcon" :class="prefixIcon" class="mr-5"/>
        <span v-if="title" class="mr-10">{{title}}</span>
        <i v-if="suffixIcon" :class="suffixIcon" />
    </el-link>

</template>

<script>

export default {
    props: {
        prefixIcon: {
            default: null
        },
        suffixIcon: {
            default: "fas el-icon-fa-arrow-circle-down"
        },

        title: {},
        linkType: {},
        linkId: {},
        fileId: {},
        fileName: {},
    },

    computed: {
        longVariant() {
            return !!this.title
        },
    },

    methods: {

        downloadFile() {

            let url;

            if (this.fileId != null)
                url = `voting/file/${this.fileId}`
            else
                url = `voting/${this.linkType}/${this.linkId}/file`

            return this.$api.files.download(url, this.fileName)
        },

    }
}
</script>

<style scoped lang="scss">

@import "~@/theme.scss";

.el-link {
    color: $dom-light-blue;
    a {
        text-decoration: none;
    }

    &.long-variant {
        background-color: $dom-light-gray;
        padding: 5px 10px;
        border: solid 1px $dom-light-blue;
        border-radius: 50px;
    }
}

</style>