<template>
    <!--
    Диалог ввода кода кодтверджения для изменения статуса голосования
    -->
    <code-confirmation-dialog v-model="isActive" :request-code="request_code" :apply-code="set_status"/>
</template>

<script>
import Toggleable from "@/views/mixins/Toggleable";
import CodeConfirmationDialog from "@/views/dialogs/CodeConfirmationDialog";
import api from "@/api";
import {mapGetters} from "vuex";

export default {
    components: {CodeConfirmationDialog},
    props: {
        status: {
            required: true,
        },
        additionalInfo:{}
    },
    mixins: [Toggleable],
    data() {
        return {
        }
    },

    computed: {
        ...mapGetters("voting",['voting_id']),
        formIsReady() {
            return !!this.code
        }
    },

    methods: {

        request_code() {
            return api.voting.getActionCode(this.voting_id, 'SET_STATUS')
        },

        set_status(code) {
            return api.voting.setStatus(this.voting_id, code, this.status, this.additionalInfo)
                .then(() => {
                    this.isActive = false;
                    this.$emit('done')
                })
        },

    }
}
</script>

<style scoped lang="scss">
@import "~@/theme.scss";

</style>
