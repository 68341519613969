<template>
    <!--
    Диалог создания бумажного бюллетеня для голосования
    -->

    <el-dialog
        :width="dialogWidth"
        class="paper-dialog"
        :class="{dialog_full_display: !$isMobile()}"
        :visible.sync="isActive"
        append-to-body
        center
        :close-on-click-modal="false"
        :show-close="false"
    >
        <template v-slot:title>
            <dom-title-closable value="Выгрузить бланк бюллетеня" @close="onCancel"/>
        </template>

        <dom-dropdown-search class="dropdown_button" label="Пользователь"
                             left-icon="fas el-icon-fa-user"
                             v-model="selectedUser" :items="users || []" value-key="user_id" text-key="user_name" use-key/>

        <template v-slot:footer>
            <dom-button-accept label="Выгрузить бланк бюллетеня" :disabled="!formIsReady" @click="onSubmit"/>
        </template>

    </el-dialog>

</template>

<script>
import Toggleable from "@/views/mixins/Toggleable";
import {mapActions, mapGetters} from "vuex";
import DomTitleClosable from "@/views/elements/DomTitleClosable";
import DomButtonAccept from "@/views/elements/DomButtonAccept";
import DomDropdownSearch from "@/views/elements/DomDropdownSearch";
import VueScreenSize from "vue-screen-size";

export default {
    props: {},
    mixins: [Toggleable, VueScreenSize.VueScreenSizeMixin, VueScreenSize.VueScreenSizeMixin, VueScreenSize.VueScreenSizeMixin, VueScreenSize.VueScreenSizeMixin],
    components: {DomButtonAccept, DomTitleClosable, DomDropdownSearch},
    data() {
        return {
            selectedUser: null,
        }
    },

    computed: {
        ...mapGetters("voting", {voting_id: 'voting_id', users: "users"}),
        dialogWidth() {
            if (this.$vssWidth > 800) return '80%'
            else return '100%'
        },
        formIsReady() {
            return !!this.selectedUser
        },
    },

    mounted() {
        this.get_users(this.voting_id)
    },

    methods: {
        ...mapActions("voting", ["get_users", "get_statistics"]),

        onSubmit() {
            const p_owner_id = this.selectedUser;
            const url = `/report/ReportEmptyDecision/pdf?p_voting_id=${this.voting_id}&p_owner_id=${p_owner_id}`
            console.log('url', url)
            this.$birt_axios.get(url)
        },

        onCancel() {
            this.isActive = false;
            this.question = '';
        },

    }
}
</script>

<style scoped lang="scss">
@import "~@/theme.scss";

.el-radio-group {
    width: 100%;
}

.user-item {
    width: 100%;
    padding: 10px;
    background-color: $dom-white;
    box-sizing: border-box;
    margin: 10px 0;
}

.dialog_full_display {
    height: 50%;
    margin-top: 35px;
    width: 100%;
}

.paper-dialog {
    ::v-deep .el-dialog {
        height: 100vh !important;
        margin-top: 0 !important;
    }

    ::v-deep .el-dialog__body {
        background: $dom-light-gray;
    }

    ::v-deep .el-dialog__footer {
        background: $dom-light-gray;
    }

    //радио кнопки
    ::v-deep .el-radio__input {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        padding: 2px;
        border: 2px solid $dom-light-blue;
    }

    ::v-deep .el-radio__input.is-checked {
        .el-radio__inner {
            border-radius: 50%;
            width: 100%;
            height: 100%;
            background: $dom-green;
        }
    }

    ::v-deep .el-radio__inner {
        border-radius: 50%;
        width: 100%;
        height: 100%;
    }

    ::v-deep .vote-radio span {
        font-size: 16px;
    }

    .vote-radio {
        margin-left: 45px;
        padding: 10px 0;
    }


}


</style>
