<template>
    <!--
    Компонент список документов
    -->

    <el-container direction="vertical">

        <dom-table
            :items="documents"
            :default-sort="{prop: 'date', order: 'ascending'}">

            <el-table-column prop="title" label="Объект собственности" sortable>
                <template v-slot="scope">
                  <span class="vote-title">{{ scope.row.property_name }}</span>
                </template>
            </el-table-column>

            <el-table-column prop="title" label="Пользователь" sortable>
                <template v-slot="scope">
                  <span class="vote-title">{{ scope.row.voting_user_name }}</span>
                </template>
            </el-table-column>

            <el-table-column prop="title" label="Название документа" sortable>
                <template v-slot="scope">
                  <span class="vote-title">{{ scope.row.file_name }}</span>
                </template>
            </el-table-column>


            <el-table-column width="40px">
                <template v-slot:header="scope">
                    <i class="fas el-icon-fa-fw el-icon-fa-eye vote-view-header"/>
                </template>
                <template v-slot="{row}">
                    <dom-file-download suffix-icon="fas el-icon-fa-fw el-icon-fa-eye"
                                       :link-type="row.doc_type"
                                       :link-id="row.link_id"
                                       :file-name="row.file_name"/>
                </template>
            </el-table-column>

        </dom-table>
    </el-container>

</template>
<script>
import {mapFields} from "vuex-map-fields";
import DomTable from "@/views/elements/DomTable";
import DomFileDownload from "@/views/elements/DomFileDownload";

export default {
    components: {DomFileDownload, DomTable},
    data: () => ({
    }),

    computed: {
        ...mapFields("voting", ['documents']),

    },

}
</script>
<style scoped lang="scss">
@import "~@/theme.scss";

.vote-title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}


.vote-view-header {
    color: $dom-gray;
}

</style>

