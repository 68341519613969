<template>
    <!--
    Компонент статистики разультатов голосования
    -->
    <div style="width: 90%; margin: 0 auto; " v-if="isShow">
        <el-table
            :data="computedTableData"
            :default-sort="{prop: 'name', order: 'ascending'}"
            border
            style="height: fit-content; margin-top: 15px">

            <el-table-column width="40px" label="#" align="center">
                <template v-slot="scope">
                    {{ scope.row.idx }}
                </template>
            </el-table-column>
            <el-table-column label="Участник общего собрания собственников" sortable>
                <template v-slot="scope">
                    <span class="vote-title">{{ scope.row.userName }}</span>
                </template>
            </el-table-column>
            <el-table-column label="Объект собственности" sortable>
                <template v-slot="scope">
                    <span class="vote-title">{{ scope.row.prpObjName }}</span>
                </template>
            </el-table-column>
            <el-table-column label="Голос" sortable width="120">
                <template v-slot="scope">
                    <span class="vote-title vote-text">{{ scope.row.vote | vote_name }}</span>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
            style="margin-top: 10px;"
            background
            :current-page.sync="currentPage"
            layout="prev, pager, next"
            :page-size="perPage"
            :total="totalItems"
        />
    </div>
</template>

<script>
import _ from "lodash";
import {mapFields} from "vuex-map-fields";

export default {
    name: "DomQuestionStatTable",
    props: {
        question: {},
        isShow: Boolean,
    },
    data: () => ({
        showByPerson: false,
        perPage: 10,
    }),
    computed: {
        ...mapFields("voting", {voteStatistics: "statistics", currentPage: "currentPage"}),

        usersStatistic() {
            return this.voteStatistics
                ? (this.voteStatistics.byQuestion[this.questionId].users || [])
                    .map((el, idx) => {
                        return {
                            idx: idx + 1,
                            vote: el.vote,
                            prpObjName: el.prpObjName,
                            userName: el.userName,
                        }
                    })
                : [];
        },

        questionId() {
            return this.question.questionId;
        },

        computedTableData() {
            const startIndex = (this.currentPage - 1) * this.perPage;
            const endIndex = Math.min(startIndex + this.perPage - 1, this.totalItems - 1);
            return _.slice(this.usersStatistic, startIndex, endIndex + 1)
        },
        totalItems() {
            return this.usersStatistic.length
        },
    }
}
</script>

<style scoped>

.vote-text {
    font-weight: 600;
    font-size: 8pt;
    word-break: keep-all;
}

</style>
